import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsProductFindRulesBySkuRequestSchema } from './request';
import { ProductsProductFindRulesBySkuResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsProductFindRulesBySkuRequestSchema extends ContractData {
*     sku: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsProductFindRulesBySkuResponseSchema extends ContractData {
*     designRules: DesignRules;
*     dpi:         number;
*     mimeTypes?:  string[];
* }
*
* export interface DesignRules {
*     fileRules?: FileRules;
*     textRules?: TextRules;
*     type:       Type;
* }
*
* export interface FileRules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* export interface TextRules {
*     notice: string;
*     regexp: string;
* }
*
* export type Type = "file" | "text";
*
* ```
*/
export class ProductsProductFindRulesBySkuRouteRpc extends BaseRouteRpc {
  static method = 'products-product-find-rules-by-sku';

  static request: ProductsProductFindRulesBySkuRequestSchema;
  static response: ProductsProductFindRulesBySkuResponseSchema;

  static role = RoleType.guest;
}
