import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsProductFindAllCategoriesForClientRequestSchema } from './request';
import { ProductsProductFindAllCategoriesForClientResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsProductFindAllCategoriesForClientRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsProductFindAllCategoriesForClientResponseSchema extends ContractData {
*     categories: Category[];
* }
*
* export interface Category {
*     id:    number;
*     title: string;
* }
*
* ```
*/
export class ProductsProductFindAllCategoriesForClientRouteRpc extends BaseRouteRpc {
  static method = 'products-product-find-all-categories-for-client';

  static request: ProductsProductFindAllCategoriesForClientRequestSchema;
  static response: ProductsProductFindAllCategoriesForClientResponseSchema;

  static role = RoleType.guest;
}
