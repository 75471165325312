import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminDeleteProductRequestSchema } from './request';
import { ProductsAdminDeleteProductResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminDeleteProductRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminDeleteProductResponseSchema extends ContractData {
* }
*
* ```
*/
export class ProductsAdminDeleteProductRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-delete-product';

  static request: ProductsAdminDeleteProductRequestSchema;
  static response: ProductsAdminDeleteProductResponseSchema;

  static role = RoleType.admin;
}
