import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthUserGetInfoRequestSchema } from './request';
import { AuthUserGetInfoResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthUserGetInfoRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthUserGetInfoResponseSchema extends ContractData {
*     email:  string;
*     name:   string;
*     userId: string;
* }
*
* ```
*/
export class AuthUserGetInfoRouteRpc extends BaseRouteRpc {
  static method = 'auth-user-get-info';

  static request: AuthUserGetInfoRequestSchema;
  static response: AuthUserGetInfoResponseSchema;

  static role = RoleType.user;
}
