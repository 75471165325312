import { BaseRouteRpc, RoleType } from '../../../../../core';
import { PaymentHistoryRetryRequestSchema } from './request';
import { PaymentHistoryRetryResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface PaymentHistoryRetryRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface PaymentHistoryRetryResponseSchema extends ContractData {
*     clientSecret?: string;
*     hasNextAction: boolean;
* }
*
* ```
*/
export class PaymentHistoryRetryRouteRpc extends BaseRouteRpc {
  static method = 'payment-history-retry';

  static request: PaymentHistoryRetryRequestSchema;
  static response: PaymentHistoryRetryResponseSchema;

  static role = RoleType.user;
}
