import type { ContractData } from '../contracts';
import type { RoleType } from '../roles';

export abstract class BaseBroadcastRpc {
  static method: string;

  static role: RoleType;

  static data: ContractData;
}
