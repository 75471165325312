import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresFindMyForFilterRequestSchema } from './request';
import { OrdersAllowedStoresFindMyForFilterResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresFindMyForFilterRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresFindMyForFilterResponseSchema extends ContractData {
*     stores: Store[];
* }
*
* export interface Store {
*     id:        number;
*     storeId:   number;
*     storeName: string;
*     userId:    string;
*     userName?: string;
* }
*
* ```
*/
export class OrdersAllowedStoresFindMyForFilterRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-find-my-for-filter';

  static request: OrdersAllowedStoresFindMyForFilterRequestSchema;
  static response: OrdersAllowedStoresFindMyForFilterResponseSchema;

  static role = RoleType.unverified;
}
