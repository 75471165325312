import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersShipStationStoreDisableRequestSchema } from './request';
import { OrdersShipStationStoreDisableResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersShipStationStoreDisableRequestSchema extends ContractData {
*     storeId: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersShipStationStoreDisableResponseSchema extends ContractData {
* }
*
* ```
*/
export class OrdersShipStationStoreDisableRouteRpc extends BaseRouteRpc {
  static method = 'orders-ship-station-store-disable';

  static request: OrdersShipStationStoreDisableRequestSchema;
  static response: OrdersShipStationStoreDisableResponseSchema;

  static role = RoleType.admin;
}
