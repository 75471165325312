import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminPersonalDiscountDeleteAllByUserRequestSchema } from './request';
import { ProductsAdminPersonalDiscountDeleteAllByUserResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminPersonalDiscountDeleteAllByUserRequestSchema extends ContractData {
*     userId: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminPersonalDiscountDeleteAllByUserResponseSchema extends ContractData {
* }
*
* ```
*/
export class ProductsAdminPersonalDiscountDeleteAllByUserRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-personal-discount-delete-all-by-user';

  static request: ProductsAdminPersonalDiscountDeleteAllByUserRequestSchema;
  static response: ProductsAdminPersonalDiscountDeleteAllByUserResponseSchema;

  static role = RoleType.admin;
}
