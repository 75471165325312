import { BaseRouteRpc, RoleType } from '../../../../../core';
import { PaymentPaymentMethodsGetMyRequestSchema } from './request';
import { PaymentPaymentMethodsGetMyResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface PaymentPaymentMethodsGetMyRequestSchema extends ContractData {
*     referralId?: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface PaymentPaymentMethodsGetMyResponseSchema extends ContractData {
*     paymentMethods: PaymentMethod[];
* }
*
* export interface PaymentMethod {
*     brand:       string;
*     enabled:     boolean;
*     firstName:   string;
*     hasErrors:   boolean;
*     id:          number;
*     last4:       string;
*     lastName:    string;
*     lastUsedAt?: string;
*     type:        string;
*     zipCode:     string;
* }
*
* ```
*/
export class PaymentPaymentMethodsGetMyRouteRpc extends BaseRouteRpc {
  static method = 'payment-payment-methods-get-my';

  static request: PaymentPaymentMethodsGetMyRequestSchema;
  static response: PaymentPaymentMethodsGetMyResponseSchema;

  static role = RoleType.user;
}
