import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminDeleteProductVariantRequestSchema } from './request';
import { ProductsAdminDeleteProductVariantResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminDeleteProductVariantRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminDeleteProductVariantResponseSchema extends ContractData {
* }
*
* ```
*/
export class ProductsAdminDeleteProductVariantRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-delete-product-variant';

  static request: ProductsAdminDeleteProductVariantRequestSchema;
  static response: ProductsAdminDeleteProductVariantResponseSchema;

  static role = RoleType.admin;
}
