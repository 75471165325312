import { BaseRouteRpc, RoleType } from '../../../../../core';
import { PaymentPaymentMethodsCreateCardRequestSchema } from './request';
import { PaymentPaymentMethodsCreateCardResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface PaymentPaymentMethodsCreateCardRequestSchema extends ContractData {
*     city:      string;
*     country:   string;
*     firstName: string;
*     lastName:  string;
*     state:     string;
*     street:    string;
*     token:     string;
*     zipCode:   string;
* }
*
* ```
* Response interface:
*
* ```
* export interface PaymentPaymentMethodsCreateCardResponseSchema extends ContractData {
*     clientSecret?: string;
*     paymentMethod: PaymentMethod;
*     status:        Status;
* }
*
* export interface PaymentMethod {
*     brand:       string;
*     enabled:     boolean;
*     firstName:   string;
*     hasErrors:   boolean;
*     id:          number;
*     last4:       string;
*     lastName:    string;
*     lastUsedAt?: string;
*     type:        string;
*     zipCode:     string;
* }
*
* export type Status = "canceled" | "processing" | "requires_action" | "requires_capture" | "requires_confirmation" | "requires_payment_method" | "succeeded";
*
* ```
*/
export class PaymentPaymentMethodsCreateCardRouteRpc extends BaseRouteRpc {
  static method = 'payment-payment-methods-create-card';

  static request: PaymentPaymentMethodsCreateCardRequestSchema;
  static response: PaymentPaymentMethodsCreateCardResponseSchema;

  static role = RoleType.user;
}
