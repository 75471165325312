import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminPersonalDiscountFindAllByUserRequestSchema } from './request';
import { ProductsAdminPersonalDiscountFindAllByUserResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminPersonalDiscountFindAllByUserRequestSchema extends ContractData {
*     page?:    number;
*     perPage?: number;
*     search?:  string;
*     userId:   string;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminPersonalDiscountFindAllByUserResponseSchema extends ContractData {
*     items: Item[];
*     total: number;
* }
*
* export interface Item {
*     discount: number;
*     name:     string;
*     pictures: string[];
*     sku:      string;
* }
*
* ```
*/
export class ProductsAdminPersonalDiscountFindAllByUserRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-personal-discount-find-all-by-user';

  static request: ProductsAdminPersonalDiscountFindAllByUserRequestSchema;
  static response: ProductsAdminPersonalDiscountFindAllByUserResponseSchema;

  static role = RoleType.admin;
}
