import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersFixOrderGetPreassignedDesignLinkRequestSchema } from './request';
import { OrdersFixOrderGetPreassignedDesignLinkResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersFixOrderGetPreassignedDesignLinkRequestSchema extends ContractData {
*     fileType:          FileType;
*     orderProductId:    number;
*     personalizationId: number;
* }
*
* export type FileType = "image/png|png" | "image/jpeg|jpg" | "image/jpeg|jpeg" | "image/gif|gif" | "image/webp|webp" | "image/svg+xml|svg" | "application/vnd|ai" | "application/pdf|ai" | "application/pdf|pdf";
*
* ```
* Response interface:
*
* ```
* export interface OrdersFixOrderGetPreassignedDesignLinkResponseSchema extends ContractData {
*     link: string;
* }
*
* ```
*/
export class OrdersFixOrderGetPreassignedDesignLinkRouteRpc extends BaseRouteRpc {
  static method = 'orders-fix-order-get-preassigned-design-link';

  static request: OrdersFixOrderGetPreassignedDesignLinkRequestSchema;
  static response: OrdersFixOrderGetPreassignedDesignLinkResponseSchema;

  static role = RoleType.unverified;
}
