import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthProfileIsAdminRequestSchema } from './request';
import { AuthProfileIsAdminResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthProfileIsAdminRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthProfileIsAdminResponseSchema extends ContractData {
*     isAdmin: boolean;
* }
*
* ```
*/
export class AuthProfileIsAdminRouteRpc extends BaseRouteRpc {
  static method = 'auth-profile-is-admin';

  static request: AuthProfileIsAdminRequestSchema;
  static response: AuthProfileIsAdminResponseSchema;

  static role = RoleType.unverified;
}
