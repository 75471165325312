import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthAuthRegisterByEmailRequestSchema } from './request';
import { AuthAuthRegisterByEmailResponseSchema } from './response';
  
/**
* @description
* ## Register by username request
*
* # Request params:
* - email
* - password --
*   Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
*
* Request interface:
* ```
* export interface AuthAuthRegisterByEmailRequestSchema extends ContractData {
*     cardData?:      CardData;
*     company?:       Company;
*     email:          string;
*     firstName:      string;
*     lastName:       string;
*     password:       string;
*     phone:          string;
*     recaptchaToken: string;
*     referralId?:    string;
* }
*
* export interface CardData {
*     addressZip: string;
*     cvc:        string;
*     expMonth:   string;
*     expYear:    string;
*     name:       string;
*     number:     string;
* }
*
* export interface Company {
*     name?:      string;
*     storeUrls?: string[];
*     url?:       string;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthAuthRegisterByEmailResponseSchema extends ContractData {
*     token:  string;
*     userId: string;
* }
*
* ```
*/
export class AuthAuthRegisterByEmailRouteRpc extends BaseRouteRpc {
  static method = 'auth-auth-register-by-email';

  static request: AuthAuthRegisterByEmailRequestSchema;
  static response: AuthAuthRegisterByEmailResponseSchema;

  static role = RoleType.guest;
}
