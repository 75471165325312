import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthAuthIsEmailBusyRequestSchema } from './request';
import { AuthAuthIsEmailBusyResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthAuthIsEmailBusyRequestSchema extends ContractData {
*     email: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthAuthIsEmailBusyResponseSchema extends ContractData {
*     isBusy: boolean;
* }
*
* ```
*/
export class AuthAuthIsEmailBusyRouteRpc extends BaseRouteRpc {
  static method = 'auth-auth-is-email-busy';

  static request: AuthAuthIsEmailBusyRequestSchema;
  static response: AuthAuthIsEmailBusyResponseSchema;

  static role = RoleType.guest;
}
