import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminDeletePictureRequestSchema } from './request';
import { ProductsAdminDeletePictureResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminDeletePictureRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminDeletePictureResponseSchema extends ContractData {
* }
*
* ```
*/
export class ProductsAdminDeletePictureRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-delete-picture';

  static request: ProductsAdminDeletePictureRequestSchema;
  static response: ProductsAdminDeletePictureResponseSchema;

  static role = RoleType.admin;
}
