import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthProfileUpdateMeRequestSchema } from './request';
import { AuthProfileUpdateMeResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthProfileUpdateMeRequestSchema extends ContractData {
*     data: Data;
* }
*
* export interface Data {
*     email:     string;
*     firstName: string;
*     lastName:  string;
*     phone:     string;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthProfileUpdateMeResponseSchema extends ContractData {
*     profile: Profile;
* }
*
* export interface Profile {
*     company?:  Company;
*     email:     string;
*     firstName: string;
*     id:        string;
*     lastName:  string;
*     phone:     string;
*     role:      number;
* }
*
* export interface Company {
*     name?:      string;
*     storeUrls?: string[];
*     url?:       string;
* }
*
* ```
*/
export class AuthProfileUpdateMeRouteRpc extends BaseRouteRpc {
  static method = 'auth-profile-update-me';

  static request: AuthProfileUpdateMeRequestSchema;
  static response: AuthProfileUpdateMeResponseSchema;

  static role = RoleType.unverified;
}
