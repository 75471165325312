import { BaseRouteRpc, RoleType } from '../../../../../core';
import { FilePreassignedLinksGenerateRequestSchema } from './request';
import { FilePreassignedLinksGenerateResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface FilePreassignedLinksGenerateRequestSchema extends ContractData {
*     files: File[];
* }
*
* export interface File {
*     name: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface FilePreassignedLinksGenerateResponseSchema extends ContractData {
*     links: string[];
* }
*
* ```
*/
export class FilePreassignedLinksGenerateRouteRpc extends BaseRouteRpc {
  static method = 'file-preassigned-links-generate';

  static request: FilePreassignedLinksGenerateRequestSchema;
  static response: FilePreassignedLinksGenerateResponseSchema;

  static role = RoleType.user;
}
