import { BaseBroadcastRpc, RoleType } from '../../../../core';
import { TickerTestResponseSchema } from './data';
  
/**
* @description
*
*
* Data interface:
* ```
* export interface TickerTestResponseSchema extends ContractData {
* }
*
* ```
*/
export class TickerTestBroadcastRpc extends BaseBroadcastRpc {
  static method = 'broadcast-ticker-test';

  static role = RoleType.guest;

  static data: TickerTestResponseSchema;
}
