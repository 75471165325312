import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthProfileFindAllForAdminRequestSchema } from './request';
import { AuthProfileFindAllForAdminResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthProfileFindAllForAdminRequestSchema extends ContractData {
*     filter?:  string;
*     page?:    number;
*     perPage?: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthProfileFindAllForAdminResponseSchema extends ContractData {
*     profiles: Profile[];
*     total:    number;
* }
*
* export interface Profile {
*     company?:     Company;
*     email:        string;
*     firstName:    string;
*     id:           string;
*     lastName:     string;
*     phone:        string;
*     role:         number;
*     hasDiscounts: boolean;
* }
*
* export interface Company {
*     name?:      string;
*     storeUrls?: string[];
*     url?:       string;
* }
*
* ```
*/
export class AuthProfileFindAllForAdminRouteRpc extends BaseRouteRpc {
  static method = 'auth-profile-find-all-for-admin';

  static request: AuthProfileFindAllForAdminRequestSchema;
  static response: AuthProfileFindAllForAdminResponseSchema;

  static role = RoleType.admin;
}
