import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminAddPicturesRequestSchema } from './request';
import { ProductsAdminAddPicturesResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminAddPicturesRequestSchema extends ContractData {
*     productId: number;
*     urls:      string[];
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminAddPicturesResponseSchema extends ContractData {
*     ids: ID[];
* }
*
* export interface ID {
*     id:  number;
*     url: string;
* }
*
* ```
*/
export class ProductsAdminAddPicturesRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-add-pictures';

  static request: ProductsAdminAddPicturesRequestSchema;
  static response: ProductsAdminAddPicturesResponseSchema;

  static role = RoleType.admin;
}
