import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersShipStationStoreAddStoreRequestSchema } from './request';
import { OrdersShipStationStoreAddStoreResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersShipStationStoreAddStoreRequestSchema extends ContractData {
*     storeId: number;
*     userId:  string;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersShipStationStoreAddStoreResponseSchema extends ContractData {
*     success: boolean;
* }
*
* ```
*/
export class OrdersShipStationStoreAddStoreRouteRpc extends BaseRouteRpc {
  static method = 'orders-ship-station-store-add-store';

  static request: OrdersShipStationStoreAddStoreRequestSchema;
  static response: OrdersShipStationStoreAddStoreResponseSchema;

  static role = RoleType.unverified;
}
