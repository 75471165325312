import { BaseRouteRpc, RoleType } from '../../../../../core';
import { PaymentHistoryGetByPaymentMethodAndStatusesRequestSchema } from './request';
import { PaymentHistoryGetByPaymentMethodAndStatusesResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface PaymentHistoryGetByPaymentMethodAndStatusesRequestSchema extends ContractData {
*     paymentMethodId: number;
*     statuses?:       Status[];
* }
*
* export type Status = "pending" | "succeeded" | "failed" | "canceled" | "retried";
*
* ```
* Response interface:
*
* ```
* export interface PaymentHistoryGetByPaymentMethodAndStatusesResponseSchema extends ContractData {
*     items: Item[];
* }
*
* export interface Item {
*     amount:    number;
*     createdAt: Date;
*     currency:  string;
*     id:        number;
*     status:    Status;
* }
*
* export type Status = "pending" | "succeeded" | "failed" | "canceled" | "retried";
*
* ```
*/
export class PaymentHistoryGetByPaymentMethodAndStatusesRouteRpc extends BaseRouteRpc {
  static method = 'payment-history-get-by-payment-method-and-statuses';

  static request: PaymentHistoryGetByPaymentMethodAndStatusesRequestSchema;
  static response: PaymentHistoryGetByPaymentMethodAndStatusesResponseSchema;

  static role = RoleType.user;
}
