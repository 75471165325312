import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresGetMyRequestSchema } from './request';
import { OrdersAllowedStoresGetMyResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresGetMyRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresGetMyResponseSchema extends ContractData {
*     storeIds: number[];
* }
*
* ```
*/
export class OrdersAllowedStoresGetMyRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-get-my';

  static request: OrdersAllowedStoresGetMyRequestSchema;
  static response: OrdersAllowedStoresGetMyResponseSchema;

  static role = RoleType.unverified;
}
