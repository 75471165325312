import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminPersonalDiscountApplyToAllByUserCopyRequestSchema } from './request';
import { ProductsAdminPersonalDiscountApplyToAllByUserCopyResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminPersonalDiscountApplyToAllByUserCopyRequestSchema extends ContractData {
*     discount: number;
*     userId:   string;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminPersonalDiscountApplyToAllByUserCopyResponseSchema extends ContractData {
* }
*
* ```
*/
export class ProductsAdminPersonalDiscountApplyToAllByUserCopyRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-personal-discount-apply-to-all-by-user copy';

  static request: ProductsAdminPersonalDiscountApplyToAllByUserCopyRequestSchema;
  static response: ProductsAdminPersonalDiscountApplyToAllByUserCopyResponseSchema;

  static role = RoleType.admin;
}
