import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminPersonalDiscountProcessCsvRequestSchema } from './request';
import { ProductsAdminPersonalDiscountProcessCsvResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminPersonalDiscountProcessCsvRequestSchema extends ContractData {
*     items: Item[];
* }
*
* export interface Item {
*     discount: number;
*     sku:      string;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminPersonalDiscountProcessCsvResponseSchema extends ContractData {
*     invalidItems: InvalidItem[];
*     validItems:   ValidItem[];
* }
*
* export interface InvalidItem {
*     discount: number;
*     sku:      string;
* }
*
* export interface ValidItem {
*     discount: number;
*     name:     string;
*     pictures: string[];
*     sku:      string;
* }
*
* ```
*/
export class ProductsAdminPersonalDiscountProcessCsvRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-personal-discount-process-csv';

  static request: ProductsAdminPersonalDiscountProcessCsvRequestSchema;
  static response: ProductsAdminPersonalDiscountProcessCsvResponseSchema;

  static role = RoleType.admin;
}
