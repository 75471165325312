import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminGetProductsRequestSchema } from './request';
import { ProductsAdminGetProductsResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminGetProductsRequestSchema extends ContractData {
*     active?:  boolean;
*     page?:    number;
*     perPage?: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminGetProductsResponseSchema extends ContractData {
*     items: Item[];
*     total: number;
* }
*
* export interface Item {
*     active:               boolean;
*     category:             Category;
*     categoryId:           number;
*     deliveryRestrictions: DeliveryRestriction[];
*     description:          string;
*     id:                   number;
*     mockupPictures:       string[];
*     name:                 string;
*     pictures:             string[];
*     variants:             Variant[];
* }
*
* export interface Category {
*     id:    number;
*     title: string;
* }
*
* export interface DeliveryRestriction {
*     active:      boolean;
*     description: string;
*     id:          number;
*     name:        string;
*     payload:     Payload;
*     type:        string;
* }
*
* export interface Payload {
*     postTypes?: string[];
*     states?:    string[];
* }
*
* export interface Variant {
*     color:           string;
*     deliveryPrice:   number;
*     designRules:     DesignRules;
*     dimensions:      string;
*     id:              number;
*     productionPrice: number;
*     sku:             string;
* }
*
* export interface DesignRules {
*     fileRules?: FileRules;
*     textRules?: TextRules;
*     type:       Type;
* }
*
* export interface FileRules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* export interface TextRules {
*     notice: string;
*     regexp: string;
* }
*
* export type Type = "file" | "text";
*
* ```
*/
export class ProductsAdminGetProductsRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-get-products';

  static request: ProductsAdminGetProductsRequestSchema;
  static response: ProductsAdminGetProductsResponseSchema;

  static role = RoleType.admin;
}
