import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersOrderHasAnyRequestSchema } from './request';
import { OrdersOrderHasAnyResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersOrderHasAnyRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersOrderHasAnyResponseSchema extends ContractData {
*     hasAny: boolean;
* }
*
* ```
*/
export class OrdersOrderHasAnyRouteRpc extends BaseRouteRpc {
  static method = 'orders-order-has-any';

  static request: OrdersOrderHasAnyRequestSchema;
  static response: OrdersOrderHasAnyResponseSchema;

  static role = RoleType.unverified;
}
