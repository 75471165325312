import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsProductIsSkuBusyRequestSchema } from './request';
import { ProductsProductIsSkuBusyResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsProductIsSkuBusyRequestSchema extends ContractData {
*     sku: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsProductIsSkuBusyResponseSchema extends ContractData {
*     busy:       boolean;
*     variantId?: number;
* }
*
* ```
*/
export class ProductsProductIsSkuBusyRouteRpc extends BaseRouteRpc {
  static method = 'products-product-is-sku-busy';

  static request: ProductsProductIsSkuBusyRequestSchema;
  static response: ProductsProductIsSkuBusyResponseSchema;

  static role = RoleType.guest;
}
