import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminGetDeliveryRestrictionsRequestSchema } from './request';
import { ProductsAdminGetDeliveryRestrictionsResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminGetDeliveryRestrictionsRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminGetDeliveryRestrictionsResponseSchema extends ContractData {
*     restrictions: Restriction[];
* }
*
* export interface Restriction {
*     active:      boolean;
*     description: string;
*     id:          number;
*     name:        string;
*     payload:     Payload;
*     type:        string;
* }
*
* export interface Payload {
*     postTypes?: string[];
*     states?:    string[];
* }
*
* ```
*/
export class ProductsAdminGetDeliveryRestrictionsRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-get-delivery-restrictions';

  static request: ProductsAdminGetDeliveryRestrictionsRequestSchema;
  static response: ProductsAdminGetDeliveryRestrictionsResponseSchema;

  static role = RoleType.admin;
}
