import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminPersonalDiscountCreateOrUpdateBatchRequestSchema } from './request';
import { ProductsAdminPersonalDiscountCreateOrUpdateBatchResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminPersonalDiscountCreateOrUpdateBatchRequestSchema extends ContractData {
*     items: Item[];
* }
*
* export interface Item {
*     discount: number;
*     sku:      string;
*     userId:   string;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminPersonalDiscountCreateOrUpdateBatchResponseSchema extends ContractData {
*     items: Item[];
* }
*
* export interface Item {
*     discount: number;
*     sku:      string;
*     userId:   string;
* }
*
* ```
*/
export class ProductsAdminPersonalDiscountCreateOrUpdateBatchRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-personal-discount-create-or-update-batch';

  static request: ProductsAdminPersonalDiscountCreateOrUpdateBatchRequestSchema;
  static response: ProductsAdminPersonalDiscountCreateOrUpdateBatchResponseSchema;

  static role = RoleType.admin;
}
