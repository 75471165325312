import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsProductFindByIdForClientRequestSchema } from './request';
import { ProductsProductFindByIdForClientResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsProductFindByIdForClientRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsProductFindByIdForClientResponseSchema extends ContractData {
*     product: Product;
* }
*
* export interface Product {
*     category:             Category;
*     categoryId:           number;
*     deliveryRestrictions: DeliveryRestriction[];
*     description:          string;
*     id:                   number;
*     mockupPictures:       string[];
*     name:                 string;
*     pictures:             string[];
*     variants:             Variant[];
* }
*
* export interface Category {
*     id:    number;
*     title: string;
* }
*
* export interface DeliveryRestriction {
*     active:      boolean;
*     description: string;
*     id:          number;
*     name:        string;
*     payload:     Payload;
*     type:        string;
* }
*
* export interface Payload {
*     postTypes?: string[];
*     states?:    string[];
* }
*
* export interface Variant {
*     color:           string;
*     deliveryPrice:   number;
*     designRules:     DesignRules;
*     dimensions:      string;
*     id:              number;
*     productionPrice: number;
*     sku:             string;
* }
*
* export interface DesignRules {
*     fileRules?: FileRules;
*     textRules?: TextRules;
*     type:       Type;
* }
*
* export interface FileRules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* export interface TextRules {
*     notice: string;
*     regexp: string;
* }
*
* export type Type = "file" | "text";
*
* ```
*/
export class ProductsProductFindByIdForClientRouteRpc extends BaseRouteRpc {
  static method = 'products-product-find-by-id-for-client';

  static request: ProductsProductFindByIdForClientRequestSchema;
  static response: ProductsProductFindByIdForClientResponseSchema;

  static role = RoleType.guest;
}
