import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresFindAllForAdminFilterRequestSchema } from './request';
import { OrdersAllowedStoresFindAllForAdminFilterResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresFindAllForAdminFilterRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresFindAllForAdminFilterResponseSchema extends ContractData {
*     stores: Store[];
* }
*
* export interface Store {
*     id:        number;
*     storeId:   number;
*     storeName: string;
*     userId:    string;
*     userName?: string;
* }
*
* ```
*/
export class OrdersAllowedStoresFindAllForAdminFilterRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-find-all-for-admin-filter';

  static request: OrdersAllowedStoresFindAllForAdminFilterRequestSchema;
  static response: OrdersAllowedStoresFindAllForAdminFilterResponseSchema;

  static role = RoleType.admin;
}
