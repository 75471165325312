import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthProfileMeRequestSchema } from './request';
import { AuthProfileMeResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthProfileMeRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthProfileMeResponseSchema extends ContractData {
*     profile: Profile;
* }
*
* export interface Profile {
*     company?:  Company;
*     email:     string;
*     firstName: string;
*     id:        string;
*     lastName:  string;
*     phone:     string;
*     role:      number;
* }
*
* export interface Company {
*     name?:      string;
*     storeUrls?: string[];
*     url?:       string;
* }
*
* ```
*/
export class AuthProfileMeRouteRpc extends BaseRouteRpc {
  static method = 'auth-profile-me';

  static request: AuthProfileMeRequestSchema;
  static response: AuthProfileMeResponseSchema;

  static role = RoleType.unverified;
}
