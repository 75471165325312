import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthAuthEnterEmailVerifcationRequestSchema } from './request';
import { AuthAuthEnterEmailVerifcationResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthAuthEnterEmailVerifcationRequestSchema extends ContractData {
*     token: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthAuthEnterEmailVerifcationResponseSchema extends ContractData {
* }
*
* ```
*/
export class AuthAuthEnterEmailVerifcationRouteRpc extends BaseRouteRpc {
  static method = 'auth-auth-enter-email-verifcation';

  static request: AuthAuthEnterEmailVerifcationRequestSchema;
  static response: AuthAuthEnterEmailVerifcationResponseSchema;

  static role = RoleType.guest;
}
