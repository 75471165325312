import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminCreateProductVariantRequestSchema } from './request';
import { ProductsAdminCreateProductVariantResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminCreateProductVariantRequestSchema extends ContractData {
*     item: Item;
* }
*
* export interface Item {
*     color:           string;
*     deliveryPrice:   number;
*     designRules:     DesignRules;
*     dimensions:      string;
*     productId:       number;
*     productionPrice: number;
*     sku:             string;
* }
*
* export interface DesignRules {
*     fileRules?: FileRules;
*     textRules?: TextRules;
*     type:       Type;
* }
*
* export interface FileRules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* export interface TextRules {
*     notice: string;
*     regexp: string;
* }
*
* export type Type = "file" | "text";
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminCreateProductVariantResponseSchema extends ContractData {
*     id: number;
* }
*
* ```
*/
export class ProductsAdminCreateProductVariantRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-create-product-variant';

  static request: ProductsAdminCreateProductVariantRequestSchema;
  static response: ProductsAdminCreateProductVariantResponseSchema;

  static role = RoleType.admin;
}
