import { BaseRouteRpc, RoleType } from '../../../../../core';
import { PaymentPaymentMethodsHasActiveRequestSchema } from './request';
import { PaymentPaymentMethodsHasActiveResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface PaymentPaymentMethodsHasActiveRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface PaymentPaymentMethodsHasActiveResponseSchema extends ContractData {
*     hasActive: boolean;
* }
*
* ```
*/
export class PaymentPaymentMethodsHasActiveRouteRpc extends BaseRouteRpc {
  static method = 'payment-payment-methods-has-active';

  static request: PaymentPaymentMethodsHasActiveRequestSchema;
  static response: PaymentPaymentMethodsHasActiveResponseSchema;

  static role = RoleType.unverified;
}
