import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthAuthGetCipherRequestSchema } from './request';
import { AuthAuthGetCipherResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthAuthGetCipherRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthAuthGetCipherResponseSchema extends ContractData {
*     cipherId:  string;
*     publicKey: string;
* }
*
* ```
*/
export class AuthAuthGetCipherRouteRpc extends BaseRouteRpc {
  static method = 'auth-auth-get-cipher';

  static request: AuthAuthGetCipherRequestSchema;
  static response: AuthAuthGetCipherResponseSchema;

  static role = RoleType.guest;
}
