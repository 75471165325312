import { BaseRouteRpc, RoleType } from '../../../../../core';
import { GatewayAuthAuthenticateWsRequestSchema } from './request';
import { GatewayAuthAuthenticateWsResponseSchema } from './response';
  
/**
* @description
* ## GatewayAuthAuthenticatews Request
*
* Request interface:
* ```
* export interface GatewayAuthAuthenticateWsRequestSchema extends ContractData {
*     token: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface GatewayAuthAuthenticateWsResponseSchema extends ContractData {
* }
*
* ```
*/
export class GatewayAuthAuthenticateWsRouteRpc extends BaseRouteRpc {
  static method = 'gateway-auth-authenticate-ws';

  static request: GatewayAuthAuthenticateWsRequestSchema;
  static response: GatewayAuthAuthenticateWsResponseSchema;

  static role = RoleType.guest;
}
