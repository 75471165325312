import { BaseRouteRpc, RoleType } from '../../../../../core';
import { PaymentInvoiceGetByOrderIdRequestSchema } from './request';
import { PaymentInvoiceGetByOrderIdResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface PaymentInvoiceGetByOrderIdRequestSchema extends ContractData {
*     orderId: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface PaymentInvoiceGetByOrderIdResponseSchema extends ContractData {
*     downloadLink: string;
* }
*
* ```
*/
export class PaymentInvoiceGetByOrderIdRouteRpc extends BaseRouteRpc {
  static method = 'payment-invoice-get-by-order-id';

  static request: PaymentInvoiceGetByOrderIdRequestSchema;
  static response: PaymentInvoiceGetByOrderIdResponseSchema;

  static role = RoleType.user;
}
