import { BaseRouteRpc, RoleType } from '../../../../../core';
import { PaymentPaymentMethodsDeleteRequestSchema } from './request';
import { PaymentPaymentMethodsDeleteResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface PaymentPaymentMethodsDeleteRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface PaymentPaymentMethodsDeleteResponseSchema extends ContractData {
* }
*
* ```
*/
export class PaymentPaymentMethodsDeleteRouteRpc extends BaseRouteRpc {
  static method = 'payment-payment-methods-delete';

  static request: PaymentPaymentMethodsDeleteRequestSchema;
  static response: PaymentPaymentMethodsDeleteResponseSchema;

  static role = RoleType.user;
}
