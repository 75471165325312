import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminUpdateProductVariantRequestSchema } from './request';
import { ProductsAdminUpdateProductVariantResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminUpdateProductVariantRequestSchema extends ContractData {
*     item: Item;
* }
*
* export interface Item {
*     id:               number;
*     color?:           string;
*     deliveryPrice?:   number;
*     designRules?:     DesignRules;
*     dimensions?:      string;
*     productionPrice?: number;
*     sku?:             string;
* }
*
* export interface DesignRules {
*     fileRules?: FileRules;
*     textRules?: TextRules;
*     type:       Type;
* }
*
* export interface FileRules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* export interface TextRules {
*     notice: string;
*     regexp: string;
* }
*
* export type Type = "file" | "text";
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminUpdateProductVariantResponseSchema extends ContractData {
* }
*
* ```
*/
export class ProductsAdminUpdateProductVariantRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-update-product-variant';

  static request: ProductsAdminUpdateProductVariantRequestSchema;
  static response: ProductsAdminUpdateProductVariantResponseSchema;

  static role = RoleType.admin;
}
