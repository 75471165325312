import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminFindAllVariantsForPersonalDiscountsRequestSchema } from './request';
import { ProductsAdminFindAllVariantsForPersonalDiscountsResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminFindAllVariantsForPersonalDiscountsRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminFindAllVariantsForPersonalDiscountsResponseSchema extends ContractData {
*     variants: Variant[];
* }
*
* export interface Variant {
*     id:   number;
*     name: string;
* }
*
* ```
*/
export class ProductsAdminFindAllVariantsForPersonalDiscountsRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-find-all-variants-for-personal-discounts';

  static request: ProductsAdminFindAllVariantsForPersonalDiscountsRequestSchema;
  static response: ProductsAdminFindAllVariantsForPersonalDiscountsResponseSchema;

  static role = RoleType.admin;
}
