import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminUpdateProductRequestSchema } from './request';
import { ProductsAdminUpdateProductResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminUpdateProductRequestSchema extends ContractData {
*     item: Item;
* }
*
* export interface Item {
*     deliveryRestrictionsIds: number[];
*     id:                      number;
*     variants:                Variant[];
*     active?:                 boolean;
*     categoryId?:             number;
*     createdAt?:              string;
*     description?:            string;
*     mockupPictures?:         string[];
*     name?:                   string;
*     pictures?:               string[];
* }
*
* export interface Variant {
*     id:               number;
*     color?:           string;
*     deliveryPrice?:   number;
*     designRules?:     DesignRules;
*     dimensions?:      string;
*     productionPrice?: number;
*     sku?:             string;
* }
*
* export interface DesignRules {
*     fileRules?: FileRules;
*     textRules?: TextRules;
*     type:       Type;
* }
*
* export interface FileRules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* export interface TextRules {
*     notice: string;
*     regexp: string;
* }
*
* export type Type = "file" | "text";
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminUpdateProductResponseSchema extends ContractData {
*     product: Product;
* }
*
* export interface Product {
*     active:               boolean;
*     category:             Category;
*     categoryId:           number;
*     deliveryRestrictions: DeliveryRestriction[];
*     description:          string;
*     id:                   number;
*     mockupPictures:       string[];
*     name:                 string;
*     pictures:             string[];
*     variants:             Variant[];
* }
*
* export interface Category {
*     id:    number;
*     title: string;
* }
*
* export interface DeliveryRestriction {
*     active:      boolean;
*     description: string;
*     id:          number;
*     name:        string;
*     payload:     Payload;
*     type:        string;
* }
*
* export interface Payload {
*     postTypes?: string[];
*     states?:    string[];
* }
*
* export interface Variant {
*     color:           string;
*     deliveryPrice:   number;
*     designRules:     DesignRules;
*     dimensions:      string;
*     id:              number;
*     productionPrice: number;
*     sku:             string;
* }
*
* export interface DesignRules {
*     fileRules?: FileRules;
*     textRules?: TextRules;
*     type:       Type;
* }
*
* export interface FileRules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* export interface TextRules {
*     notice: string;
*     regexp: string;
* }
*
* export type Type = "file" | "text";
*
* ```
*/
export class ProductsAdminUpdateProductRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-update-product';

  static request: ProductsAdminUpdateProductRequestSchema;
  static response: ProductsAdminUpdateProductResponseSchema;

  static role = RoleType.admin;
}
