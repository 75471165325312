import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminDeleteProductImageRequestSchema } from './request';
import { ProductsAdminDeleteProductImageResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminDeleteProductImageRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminDeleteProductImageResponseSchema extends ContractData {
* }
*
* ```
*/
export class ProductsAdminDeleteProductImageRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-delete-product-image';

  static request: ProductsAdminDeleteProductImageRequestSchema;
  static response: ProductsAdminDeleteProductImageResponseSchema;

  static role = RoleType.admin;
}
