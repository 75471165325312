import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsProductFindBySkuForCorrectionRequestSchema } from './request';
import { ProductsProductFindBySkuForCorrectionResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsProductFindBySkuForCorrectionRequestSchema extends ContractData {
*     sku: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsProductFindBySkuForCorrectionResponseSchema extends ContractData {
*     product: Product;
* }
*
* export interface Product {
*     name?:     string;
*     pictures?: string[];
*     variant?:  Variant;
* }
*
* export interface Variant {
*     color:           string;
*     deliveryPrice:   number;
*     designRules:     DesignRules;
*     dimensions:      string;
*     id:              number;
*     productionPrice: number;
*     sku:             string;
* }
*
* export interface DesignRules {
*     fileRules?: FileRules;
*     textRules?: TextRules;
*     type:       Type;
* }
*
* export interface FileRules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* export interface TextRules {
*     notice: string;
*     regexp: string;
* }
*
* export type Type = "file" | "text";
*
* ```
*/
export class ProductsProductFindBySkuForCorrectionRouteRpc extends BaseRouteRpc {
  static method = 'products-product-find-by-sku-for-correction';

  static request: ProductsProductFindBySkuForCorrectionRequestSchema;
  static response: ProductsProductFindBySkuForCorrectionResponseSchema;

  static role = RoleType.unverified;
}
