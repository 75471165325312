import { BaseRouteRpc, RoleType } from '../../../../../core';
import { PaymentActionRequiredGetDataForOrderVerificationRequestSchema } from './request';
import { PaymentActionRequiredGetDataForOrderVerificationResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface PaymentActionRequiredGetDataForOrderVerificationRequestSchema extends ContractData {
*     orderId: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface PaymentActionRequiredGetDataForOrderVerificationResponseSchema extends ContractData {
*     clientSecret: string;
* }
*
* ```
*/
export class PaymentActionRequiredGetDataForOrderVerificationRouteRpc extends BaseRouteRpc {
  static method = 'payment-action-required-get-data-for-order-verification';

  static request: PaymentActionRequiredGetDataForOrderVerificationRequestSchema;
  static response: PaymentActionRequiredGetDataForOrderVerificationResponseSchema;

  static role = RoleType.user;
}
