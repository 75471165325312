import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthAuthSendEmailVerificationRequestSchema } from './request';
import { AuthAuthSendEmailVerificationResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthAuthSendEmailVerificationRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthAuthSendEmailVerificationResponseSchema extends ContractData {
* }
*
* ```
*/
export class AuthAuthSendEmailVerificationRouteRpc extends BaseRouteRpc {
  static method = 'auth-auth-send-email-verification';

  static request: AuthAuthSendEmailVerificationRequestSchema;
  static response: AuthAuthSendEmailVerificationResponseSchema;

  static role = RoleType.guest;
}
