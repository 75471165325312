import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsProductFindRulesBySkusRequestSchema } from './request';
import { ProductsProductFindRulesBySkusResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsProductFindRulesBySkusRequestSchema extends ContractData {
*     skus: string[];
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsProductFindRulesBySkusResponseSchema extends ContractData {
*     results: Result[];
* }
*
* export interface Result {
*     designRules: DesignRules;
*     dpi:         number;
*     mimeTypes?:  string[];
*     sku:         string;
* }
*
* export interface DesignRules {
*     fileRules?: FileRules;
*     textRules?: TextRules;
*     type:       Type;
* }
*
* export interface FileRules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* export interface TextRules {
*     notice: string;
*     regexp: string;
* }
*
* export type Type = "file" | "text";
*
* ```
*/
export class ProductsProductFindRulesBySkusRouteRpc extends BaseRouteRpc {
  static method = 'products-product-find-rules-by-skus';

  static request: ProductsProductFindRulesBySkusRequestSchema;
  static response: ProductsProductFindRulesBySkusResponseSchema;

  static role = RoleType.guest;
}
