import type { ContractData } from '../contracts';
import { RoleType } from '../roles';

export abstract class BaseRouteRpc {
  static method: string;

  static request: ContractData;
  static response: ContractData;

  static role: RoleType;
}
