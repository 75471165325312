import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminGetCategoriesRequestSchema } from './request';
import { ProductsAdminGetCategoriesResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminGetCategoriesRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminGetCategoriesResponseSchema extends ContractData {
*     categories: Category[];
* }
*
* export interface Category {
*     createdAt: Date;
*     id:        number;
*     title:     string;
* }
*
* ```
*/
export class ProductsAdminGetCategoriesRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-get-categories';

  static request: ProductsAdminGetCategoriesRequestSchema;
  static response: ProductsAdminGetCategoriesResponseSchema;

  static role = RoleType.admin;
}
