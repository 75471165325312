import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthAuthLoginByEmailRequestSchema } from './request';
import { AuthAuthLoginByEmailResponseSchema } from './response';
  
/**
* @description
* ## Login by username request
*
* Logins user if credentials are valid
*
* Request interface:
* ```
* export interface AuthAuthLoginByEmailRequestSchema extends ContractData {
*     email:    string;
*     password: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthAuthLoginByEmailResponseSchema extends ContractData {
*     token:  string;
*     userId: string;
* }
*
* ```
*/
export class AuthAuthLoginByEmailRouteRpc extends BaseRouteRpc {
  static method = 'auth-auth-login-by-email';

  static request: AuthAuthLoginByEmailRequestSchema;
  static response: AuthAuthLoginByEmailResponseSchema;

  static role = RoleType.guest;
}
