import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersShipStationStoreLookupByIdRequestSchema } from './request';
import { OrdersShipStationStoreLookupByIdResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersShipStationStoreLookupByIdRequestSchema extends ContractData {
*     storeId: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersShipStationStoreLookupByIdResponseSchema extends ContractData {
*     name?: string;
* }
*
* ```
*/
export class OrdersShipStationStoreLookupByIdRouteRpc extends BaseRouteRpc {
  static method = 'orders-ship-station-store-lookup-by-id';

  static request: OrdersShipStationStoreLookupByIdRequestSchema;
  static response: OrdersShipStationStoreLookupByIdResponseSchema;

  static role = RoleType.admin;
}
