import { BaseRouteRpc, RoleType } from '../../../../../core';
import { AuthProfileUpdateByAdminRequestSchema } from './request';
import { AuthProfileUpdateByAdminResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface AuthProfileUpdateByAdminRequestSchema extends ContractData {
*     company?: Company;
*     profile?: Profile;
*     userId:   string;
* }
*
* export interface Company {
*     name?:      string;
*     storeUrls?: string[];
*     url?:       string;
* }
*
* export interface Profile {
*     email:     string;
*     firstName: string;
*     lastName:  string;
*     phone:     string;
* }
*
* ```
* Response interface:
*
* ```
* export interface AuthProfileUpdateByAdminResponseSchema extends ContractData {
*     profile: Profile;
* }
*
* export interface Profile {
*     company?:     Company;
*     email:        string;
*     firstName:    string;
*     id:           string;
*     lastName:     string;
*     phone:        string;
*     role:         number;
*     hasDiscounts: boolean;
* }
*
* export interface Company {
*     name?:      string;
*     storeUrls?: string[];
*     url?:       string;
* }
*
* ```
*/
export class AuthProfileUpdateByAdminRouteRpc extends BaseRouteRpc {
  static method = 'auth-profile-update-by-admin';

  static request: AuthProfileUpdateByAdminRequestSchema;
  static response: AuthProfileUpdateByAdminResponseSchema;

  static role = RoleType.admin;
}
